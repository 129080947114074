.ocean_textarea {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  padding: 12px;
  font-size: 16px;
  outline: none;
}

.ocean_textarea:focus {
  border: 2px solid #5946d4;
}
