@font-face {
  font-family: 'Helvetica';
  src: url('assets/fonts/helvetica-neue-lt-geo-55-roman-17645fe55b2c8add5827f34e8293e43e.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  src: url('assets/fonts/helvetica-neue-lt-geo-65-medium-dff1931edecfd6e1e629209a6244bab8.ttf')
    format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Helvetica';
  src: url('assets/fonts/helvetica-neue-lt-geo-75-bold-e98239eba6a80d2b0707a48c4ce37ce7.ttf')
    format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Helvetica';
  src: url('assets/fonts/helvetica-neue-lt-geo-75-bold-caps-8ac4bc22c200b3fe99bdfd18fea98f4f.ttf')
    format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Mrgvlovani';
  src: url('assets/fonts/bpg_mrgvlovani_caps_2010.ttf') format('truetype');
}

@font-face {
  font-family: 'Akribal';
  src: url('assets/fonts/Arkibal-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Akribal';
  src: url('assets/fonts/Arkibal-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'Akribal';
  src: url('assets/fonts/Arkibal-Regular.otf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
}

/* FIRAGO FONT */

@font-face {
  font-family: 'FiraGO';
  font-weight: 500;
  src: url('assets/fonts/FiraGO-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'FiraGO';
  font-weight: 600;
  src: url('assets/fonts/FiraGO-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'FiraGO';
  font-weight: 300;
  src: url('assets/fonts/FiraGO-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'FiraGO';
  font-weight: 400;
  src: url('assets/fonts/FiraGO-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'FiraGO';
  font-weight: 700;
  src: url('assets/fonts/FiraGO-Bold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'FiraGO';
}

a {
  color: unset;
  text-decoration: none;
}

.inputz:focus {
  outline-color: #673ab7;
}

.scroll-lock {
  overflow: hidden;
}

@import url('./misc.css');
