.fc-header-toolbar {
  display: none !important;
}

.fc th {
  text-align: left;
}

/* .fc-scrollgrid tbody tr {
  height: 106px;
} */
.fc-theme-standard tr {
  border: 1px solid #ddd;
}
.fc-timegrid-event-short .fc-event-main-frame {
  overflow: visible;
}

.fc-col-header-cell {
  height: 64px !important;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
}

.fc-col-header-cell div {
  text-align: end;
  margin-top: 32px;
  margin-right: 4px;
}

.fc-media-screen {
  height: 675px !important;
}

.course-event {
  max-width: 70%;
}

.fc-timegrid-col-events .course-event {
  max-width: 100%;
}

.course-event .fc-event-title {
  text-overflow: ellipsis;
}

.fc .fc-popover {
  border-radius: 16px;
}

.fc .fc-popover .fc-popover-header {
  background-color: #fff;
  font-size: 20px;
  font-family: 'FiraGo';
  padding: 10px;
}
.fc .fc-popover .fc-popover-title {
  font-weight: bold;
  text-transform: uppercase;
}

.fc-popover-body .fc-daygrid-event-harness .course-event {
  min-width: 100%;
}
.fc-popover-body .fc-daygrid-event {
  line-height: 2;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc .fc-scrollgrid-section-body table{
  border-bottom-style: initial !important;
}

.fc-timegrid-slot {
  height: 30px !important;
  margin: 0 auto;
}

.fc-event-title-container ::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  margin-left: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background: #00ce7c;
}

.fc-daygrid-day-bottom {
  border: none;
}

.fc-daygrid-day-number {
  font-weight: 500;
  font-size: 20px;
}

.fc-event-title {
  font-size: 16px;
  font-weight: bold !important;
  cursor: pointer;
}

.fc-event-time {
  display: none;
}

.fc-daygrid-event-dot {
  margin-left: 3px !important;
  margin-right: 3px !important;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2.75) solid
    var(--fc-event-border-color, #00ce7c) !important;
}

.fc-timegrid-event-short .fc-event-main-frame {
  overflow: visible !important;
}

.fc-event-title-container {
  border: none;
  padding-bottom: 3px;
  box-shadow: transparent;
  height: 45px;
  background: #2a7de1 !important;
  border-radius: 8px !important;
  padding: 3px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none !important;
}
.fc-v-event {
  background-color: transparent !important;
  border: none !important;
}

.fc-daygrid-event:hover {
  background: none;
}

.fc-event-title {
  font-weight: 400 !important;
  font-size: 12px;
}
.fc-event-title.fc-sticky {
  display: block;
}

.fc-daygrid .fc-daygrid-event.fc-event-end {
  text-decoration: none;
  padding: 0;
  margin-top: 4px !important;
}

.fc-daygrid .fc-daygrid-event.fc-event-end:hover {
  background: #2a7de1;
  border-radius: 23px;
  color: white;
  text-decoration: none;
}

.fc-day-today .fc-daygrid-day-bg {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 90%;
  height: 4px;
  transform: translateX(10px);
  border-radius: 4px;
}

.fc-media-screen {
  background: white;
}
.fc-event-time,
.fc-event-title {
  text-decoration: none;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  padding-block: 12px;
  padding-left: 32px;
  padding-right: 24px;
  /* background-image: url('src/assets/icons/banner-bg.svg'); */
  background-size: cover;
  background-clip: padding-box;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.fc-currentDate-button {
  border: none !important;
  color: #000000 !important;
}

.fc-timegrid-col {
  background-color: transparent !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

.fc-agendaWeek-view .fc-today,
#calendar .fc-agendaDay-view .fc-today {
  background: transparent !important;
}

.date_weekday {
  font-weight: 500;
  text-transform: uppercase;
  margin-left: -3px;
  font-size: 18px;
}

.fc-timegrid-slots table tbody tr:nth-child(even) {
  display: none;
}

.fc-event-title::before {
  width: 5px;
  height: 5px;
  margin: 2px;
}

.date_day {
  width: 56px;
  height: 56px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  margin-top: 13px;
  font-weight: 500;
}
.date_day.active {
  background-color: #f7d51b;
  color: #ffffff;
}

.fc-timeGridWeek-view .fc-scrollgrid-sync-inner {
  text-align: center;
}

.fc-timeGridDay-view .date_day,
.fc-timeGridWeek-view .date_day {
  display: flex;
}

.fc-timeGridDay-view .date_weekday {
  margin-left: 62px;
}
.fc-timeGridDay-view .date_day {
  margin-left: 50px;
}

.fc .fc-timegrid-slot-label,
.fc .fc-timegrid-slot-minor {
  border: none;
}

.fc .fc-timegrid-slot {
  height: 3.5rem;
}
.fc .fc-timegrid-slot-label {
  vertical-align: bottom;
  transform: translateY(10px);
  text-transform: uppercase;
  font-size: 14px;
  padding-right: 5px;
}

.fc-day-sat,
.fc-day-sun {
  opacity: 0.3;
}

.fc-day-other {
  opacity: 1;
}

.fc .fc-daygrid-day.fc-day-today {
  color: #00ce7c;
}
.fc-daygrid-day-frame {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
}

.fc-daygrid-day-top {
  position: absolute;
  bottom: -2px;
  right: 6px;
}

.css-kgxa0u-MuiGrid-root {
  min-height: 1080px !important;
}

.fc .fc-timegrid-slot-label-cushion {
  font-weight: 500;
}

/* DAY STYLES */
.fc-col-header-cell-cushion {
  display: flex !important;
  flex-direction: row-reverse;
}
.fc-scrollgrid-sync-inner {
  display: flex;
  justify-content: flex-start;
}
.fc-col-header-cell-cushion .date_day {
  margin: 0 !important;
  background: transparent !important;
  width: 35px;
  height: 35px;
  color: black;
  font-size: 18px;
  margin-top: -18px !important;
}

.fc-col-header-cell-cushion .date_day.active {
  margin: 0 !important;
  background: #01ce7c !important;
  width: 35px;
  height: 35px;
  color: black;
  font-size: 18px;
  margin-top: -18px !important;
}

.fc-col-header-cell-cushion .date_weekday {
  margin: 0 !important;
  font-size: 18px;
  margin-top: -13px !important;
}

.fc-day-today .fc-scrollgrid-sync-inner {
  display: flex;
  justify-content: flex-start;
}

.fc-timegrid-slots table tbody tr {
  height: 53px !important;
}

@media screen and (max-width: 1820px) {
  .date_weekday {
    margin-left: -5px;
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .MuiBox-root #calendar_head {
    display: none;
  }
}

@media screen and (max-width: 950px) {
  .date_weekday {
    margin-left: 0px;
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .fc-timeGridWeek-view .fc-scrollgrid-sync-inner {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fc-timeGridWeek-view .date_weekday {
    font-size: 14px;
  }
  .fc-timeGridWeek-view .date_day {
    font-size: 18px;
    width: 30px;
    height: 30px;
    margin-top: 0;
  }
}

@media screen and (max-width: 565px) {
  .fc-dayGridMonth-view .fc-scrollgrid-sync-inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
  }

  .fc-timeGridDay-view .date_weekday {
    margin-left: 0;
  }
  .fc-timeGridDay-view .date_day {
    margin-left: 1px;
    font-size: 18px;
    width: 30px;
    height: 30px;
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .date_weekday,
  .date_day,
  .fc-daygrid-day-number {
    font-weight: 300;
  }

  .fc-popover-body .fc-daygrid-dot-event .fc-event-title {
    overflow: visible;
  }

  .fc-popover-body .fc-daygrid-event-harness {
    margin: 15px 0px;
  }

  .fc-popover {
    min-width: 250px;
  }
  .fc .fc-more-popover .fc-popover-body {
    padding: 0px 40px !important;
  }

  .fc-popover-body .fc-daygrid-dot-event .fc-daygrid-event-dot {
    position: relative;
    left: -30px;
  }

  .fc-col-header-cell {
    height: 32px !important;
    padding: 5px !important;
    padding-right: 3px !important;
    padding: 0 !important;
  }

  .fc-col-header-cell div {
    text-align: end;
    margin-top: 4px;
    margin-right: -12px;
    padding: 0 !important;
  }

  .fc-scrollgrid-sync-table {
    height: auto !important;
  }
  .fc-scrollgrid-sync-table tbody tr {
    height: 56px !important;
  }

  .fc-media-screen {
    height: 370px !important;
  }
}

@media only screen and (max-width: 576px) {
  .fc-daygrid-day-bg {
    display: none;
  }

  .fc-scrollgrid-sync-inner {
    display: block;
    overflow: hidden;
  }

  .date_weekday {
    font-weight: 600 !important;
  }

  .fc-event-title {
    display: none;
  }

  .fc-popover-body .fc-event-title {
    display: block;
    overflow: initial;
  }

  .fc-daygrid-day-number {
    font-size: 14px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-weight: bold !important;
    right: 0px;
    bottom: -5px;
  }

  .fc-daygrid .fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid
      var(--fc-event-border-color, #2a7de1) !important;
  }

  .fc-day-today .fc-daygrid-day-number {
    color: #00ce7c !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border-radius: 50%;
    color: #fff;
  }

  .fc-event-title-container ::before {
    content: none;
  }
  .fc-scrollgrid-sync-table {
    width: 100% !important;
  }
  .fc-daygrid-body {
    width: 100% !important;
  }
  .fc-col-header {
    width: 100% !important;
  }
}

@media (max-width: 576px) {
  .fc-col-header-cell-cushion .date_weekday {
    margin-top: 0px !important;
  }

  .fc-col-header-cell-cushion .date_day {
    margin: 0 !important;
    background: transparent !important;
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-top: -4px !important;
  }

  .fc-timeGridWeek-view .fc-scrollgrid-sync-inner {
    justify-content: flex-start;
  }
  .fc-col-header-cell-cushion {
    flex-direction: column;
    align-items: center;
  }

  .fc-col-header-cell-cushion .date_day.active {
    margin: 0 !important;
    background: transparent !important;
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-top: -4px !important;
  }

  .fc-popover-body .fc-daygrid-event {
    height: 18px;
  }

  .fc-timegrid-body {
    width: 100% !important;
    margin: 0 !important;
  }
  .fc-timegrid-slots table {
    width: 100% !important;
    margin: 0 !important;
  }
}
