.vanish {
  display: none !important;
}

@media (max-width: 576px) {
  .fc-event-title {
    max-width: 34px !important;
    font-size: 12px;
    position: absolute;
    left: -18px;
    top: 4px;
  }
  .fc-daygrid-day-number {
    margin-left: -20px;
    padding-left: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sc-iqcoie {
  z-index: 1000 !important;
}

@media (max-width: 500px) {
  .sc-afnQL {
    width: 160px !important;
    height: 260px;
  }
  .AAjes {
    display: none !important;
  }
  .jUBuis {
    display: none !important;
  }
}

#Group_64865 {
  display: none !important;
}

.MuiPaper-root::-webkit-scrollbar {
  width: 8px;
}
.MuiPaper-root::-webkit-scrollbar-thumb {
  border-radius: 8px 0 0 8px;
  height: 55px;
}
.MuiPaper-root::-webkit-scrollbar-track {
  background: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
